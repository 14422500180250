<template>
  <v-main>

    <v-container
        id="users-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-text-box-search-outline"
          class="px-5 py-5"
      >

        <v-card-text>

          <v-form ref="invoiceSearchForm"
                  v-model="valid"
                  @submit.prevent="validate"
                  lazy-validation
          >

            <div>
              <v-alert
                  type="error"
                  outlined
                  v-for="(error, i) in errors"
                  :key="i"
                  dense
              >
                {{ error }}
              </v-alert>
            </div>

            <div>
              <v-alert
                  type="warning"
                  outlined
                  v-for="(warning, i) in warnings"
                  :key="i"
                  dense
              >
                {{ warning }}
              </v-alert>
            </div>

            <v-row>
              <v-col
                  cols="12"
                  sm="3"
              >
                <v-text-field
                    v-model="lastName"
                    label="Bill to Last Name"
                    type="text"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="3"
              >
                <v-text-field
                    v-model="firstName"
                    label="Bill to First Name"
                    type="text"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="6"
                  sm="3"
              >
                <div class="my-2">
                  <v-btn
                      color="primary"
                      dark
                      type="submit"
                  >
                    Search
                  </v-btn>
                </div>
              </v-col>

            </v-row>

          </v-form>

        </v-card-text>



        <v-card-text v-if="invoices">

          <v-simple-table
            fixed-header
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="invoice-num text-subtitle-2">Invoice #</th>
                <th class="bill-to text-subtitle-2">Bill To</th>
                <th class="invoice-amount text-subtitle-2 text-right">Amount</th>
                <th class="invoice-balance text-subtitle-2 text-right">Balance</th>
                <th class="invoice-date text-subtitle-2">Date</th>
                <th class="due-date text-subtitle-2">Due Date</th>
                <th class="invoice-status text-subtitle-2">Status</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="invoice in invoices"
                  :key="invoice.id"
              >
                <td>
                  <router-link :to="{ name: 'view_invoice', params: {invoiceId: invoice.id} }">
                    {{ invoice.id }}
                  </router-link>
                </td>
                <td>
                  {{ invoice.user.firstName }} {{ invoice.user.middleName }} {{ invoice.user.lastName }}
                </td>
                <td class="text-right">{{ invoice.total | formatCurrency }}</td>
                <td class="text-right">{{ invoice.balance | formatCurrency }}</td>
                <td>{{ invoice.invoiceDate | formatDate }}</td>
                <td>{{ invoice.dueDate | formatDate }}</td>
                <td>{{ invoiceStatusMap[invoice.status] }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>

      </base-material-card>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import constants from '@/utils/constants'
import validation from "@/utils/validation";

export default {
  name: 'CoreInvoiceSearchView',

  watch: {
    warnings: function () {
      scroll(0, 0)
    },
    errors: function () {
      scroll(0, 0)
    }
  },

  methods: {
    validate() {
      this.errors = []
      this.warnings = []

      if (this.lastName === null && this.firstName === null) {
        this.errors.push('At least one query field is required')
        return
      }

      if(this.$refs.invoiceSearchForm.validate()) {
        this.searchInvoices()
      }
    },
    searchInvoices() {
      const payload = {
        firstName: this.firstName,
        lastName: this.lastName
      }
      api
        .searchInvoices(payload)
        .then(response => {
          this.invoices = response.data
          if (this.invoices.length === 0) {
            this.warnings.push('No invoices found matching criteria')
          }
        })
        .catch(err => {
          this.errors.push(api.parseResponseError(err))
        })
    }
  },

  data() {
    return {
      valid: true,
      errors: [],
      warnings: [],
      firstName: null,
      lastName: null,
      invoices: [],
      invoiceStatusMap: constants.getInvoiceStatusMap(),
      invoiceTypes: constants.getInvoiceTypes()
    }
  },
}
</script>

<style lang="sass">
th.invoice-amount, th.invoice-balance, th.invoice-status
  width: 10%

th.invoice-num, th.invoice-date, th.due-date, th.invoice-amount, th.invoice-status
  width: 10%

</style>